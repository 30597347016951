<template>
  <div class="mt-4">
    <div class="card top_primary_card bg-primary font-bold text-white p-2 rounded-xl  w-full">
      <div class="flex justify-center items-center">
      <!-- <div class="flex justify-between items-center"> -->
        <h1 class="heading-2 text-center">{{parseInt(this.$route.params.quoteId) > 0 ? `Quotation Id: ${quoteDetailObj.quotationMasterId}` : 'Create Quote'}}</h1>
        <div class="flex items-center gap-3">
          </div>
      </div>
    </div>
    <div class="flex justify-center" >
        <div class=" card rounded-xl bg-white p-4 my-4 sm:w-full lg:w-10/12 xl:w-8/12 2xl:w-6/12">
            <div>
                <div class="sm:grid grid-cols-2 gap-4">
                  <div class="col col-span-1">
                        <Dropdown
                        :disabled="isDisable"
                        :inputext="searchForCustomer" 
                        :inputId="'searchwp-Customer'"
                        :lableText="'Customer/Lead'"
                        :placholderText="`Customer/Lead`"
                        :autoFocus="false"
                        :showArrow="true"
                        :setReadOnly="false"
                        :searchCallApi="true"
                        :fieldError="errObj.custErr !== ''"
                        :showPlus="true"
                        :keyName="'fullName'"
                        :listId="'customerlistid'"
                        :items="customersList"
                        @enterkeyPressAction="errObj.custErr =''"
                        @inputChangeAction="callsearchCust"
                        @addNew="addNewCustomer"
                        @selectNewForVal="selectNewCustomer"
                        />
                        <p v-if="errObj.custErr !== ''" class="text-error heading-7">{{errObj.custErr}}</p>
                    </div>
                  </div>
                  <div class="sm:grid grid-cols-2 gap-4">
                    <div class="mb-4 sm:mb-0">
                        <TextField :placholderText="`Quote Date`" :setReadOnly="true" :autoFocus="false" :inputext="sendObject.quoteDate" :showDate="true" :lableText="'Quote Date'" :inputId="'Invoicedkid'" @onClickInputAction="invoiceDatePickShow = true" />
                        <div v-if="invoiceDatePickShow" class="popup_overlay">
                            <div style="height: 435px; width: 306px;" class="custom_dialog rounded-xl border border-gray4 ring-gray4 ">
                                <DateTimePickerCustom
                                    v-model="sendObject.quoteDate"
                                    v-if="invoiceDatePickShow"
                                    :showDate="true"
                                    :title="'Quote Date'"
                                    :showOnlyDate="true"
                                    :showStartDate="true"
                                    :projectSection="true"
                                    @input="updateDate()"
                                    @closeDateTimepickerDialog="invoiceDatePickShow = false">
                                </DateTimePickerCustom>
                            </div>
                        </div>
                        <p v-if="errObj.dateError !== ''" class="text-error heading-7">{{errObj.dateError}}</p>
                    </div>
                    <div class="mb-4 sm:mb-0">
                        <TextField :placholderText="`Expire Date`" :setReadOnly="true" :autoFocus="false" :inputext="sendObject.expiryDate" :showDate="true" :lableText="'Expire Date'" :inputId="'invduedateid'" @onClickInputAction="invoiceDueDatePickShow = true" />
                        <div v-if="invoiceDueDatePickShow" class="popup_overlay">
                            <div style="height: 435px; width: 306px;" class="custom_dialog rounded-xl border border-gray4 ring-gray4 ">
                                <DateTimePickerCustom
                                    v-model="sendObject.expiryDate"
                                    v-if="invoiceDueDatePickShow"
                                    :showDate="true"
                                    :title="'Expire Date'"
                                    :showOnlyDate="true"
                                    :showStartDate="true"
                                    :min="sendObject.quoteDate"
                                    @input="updateDate()"
                                    :minDate="sendObject.quoteDate"
                                    :projectSection="true"
                                    @closeDateTimepickerDialog="invoiceDueDatePickShow = false">
                                </DateTimePickerCustom>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                  <div  class="mt-2" v-if="sendObject.itemList.length > 0">
                     <p class=" text-gray4 heading-4 font-bold mb-4">Items list:</p>
                     <div class="px-4 cursor-pointer hover:bg-gray1 border-b py-1" v-for="(data, index) in sendObject.itemList" :key="index + 'key'" @click="showitemDetail(data)">
                        <div class="text-text2 text-sm flex mb-1 font-bold justify-between">
                            <p class="pr-4" >{{data.itemName}}</p>
                            <p class="" >{{data.itemAmount | amountOnlyNumber}}</p>
                        </div>
                        <div class="text-text2 flex">
                            <p class="w-32 sm:w-72 heading-7">{{data.itemPrice | amountOnlyNumber}}</p>
                            <span class="w-32 sm:w-72 heading-7">Qty:{{data.itemQty}}</span>
                        </div>
                        <div class="text-text2 flex">
                            <p class="heading-7 text-gray4">{{data.itemDescription.substr(0, 100)}} <span v-if="data.itemDescription.length > 100">...</span></p>
                        </div>
                     </div>
                  </div>
                   <div class="mt-2 py-2 heading-5">
                        <span v-if="sendObject.itemList.length === 0" @click="selectInvItems" class="text-primary font-bold mt-2 cursor-pointer">+ Add Item</span>
                        <span v-else  @click="selectInvItems" class="text-primary font-bold mt-2 cursor-pointer">+ Add more Item</span>
                      <p v-if="errObj.itemErr !== ''" class="text-error heading-7">{{errObj.itemErr}}</p>
                    </div>
                      <div class="flex justify-between px-4">
                          <p class="text-text2 font-bold w-full text-right">Total:</p>
                          <p class="text-text2 font-bold w-full text-right">{{sendObject.subTotal.toFixed(2) | amountOnlyNumber}}</p>
                      </div>
                     <div class="divider my-2"></div>
                      <div class="flex justify-between px-4">
                        <span v-if="sendObject.itemList.length > 0" class="cursor-pointer text-primary heading-4" @click="openDepositPopup(sendObject)"><i class="fa-solid fa-pen-to-square"></i></span>
                        <p class=" font-bold w-full text-right" :class="errObj.depErr ? 'text-error' : 'text-text2'">{{depistTile}}</p>
                        <p class="font-bold w-full text-right" :class="errObj.depErr ? 'text-error' : 'text-text2'">{{sendObject.depositAmt | amountOnlyNumber}}</p>
                      </div>
                    </div>
                    <p v-if="errObj.depErr !== ''" class="text-error heading-7 text-right">{{errObj.depErr}}</p>
                <div v-if="!iscustomerNotes" class="mt-2">
                  <span  @click="iscustomerNotes = true" class="text-primary heading-6 mt-2 cursor-pointer">+ Notes on quote (visible to lead/customer)</span>
                </div>
                 <div class="mb-4" v-if="iscustomerNotes">
                    <TextAreaInput
                      :inputId="'invicenoteid'"
                      :inputext="sendObject.customerNotes"
                      :placholderText="`Write notes here...`"
                      :lableText="'Notes on quote (visible to lead/customer)'"
                      :inputType="'text'"
                      :autoFocus="false"
                      :textMaxlength="1000"
                      :showcharLimit="true"
                      :cols="50"
                      :rows="3"
                      :setReadOnly="false"
                      @inputChangeAction="(data) => sendObject.customerNotes = data"  />
                </div>
                <div  v-if="!internlNotes" class="mt-2">
                  <span @click="internlNotes = true" class="text-primary mt-2 heading-6 cursor-pointer">+ Internal Notes (not visible on quote)</span>
                </div>
                 <div class="mb-4" v-if="internlNotes">
                    <TextAreaInput
                      :inputId="'invicenoteid123'"
                      :inputext="sendObject.internalNotes"
                      :placholderText="`Write notes here...`"
                      :lableText="'Internal Notes (not visible on quote)'"
                      :inputType="'text'"
                      :autoFocus="false"
                      :textMaxlength="1000"
                      :showcharLimit="true"
                      :cols="50"
                      :rows="3"
                      :setReadOnly="false"
                      @inputChangeAction="(data) => sendObject.internalNotes = data"  />
                </div>
            </div>
            <div class="flex justify-end ">
              <div class="text-rigth flex gap-2">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="$router.go(-1)"/>
                <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="createInvoice()"/>
              </div>
          </div>
        </div>
      </div>
    <div>
    </div>
    <AddCustomer v-if="addCustomer" />
    <ItemSelection :itemsSelected="sendObject.itemList" v-if="itemSelect" />
    <SelectedItmDetail  :selectedItemForDetail="selectedItemForDetail" v-if="itemSelectDetail" />
    <DepositPopup :Depobject="sendObjectForDeposit" v-if="depositDialog" />
   </div>
</template>
<script>
import MyJobApp from "@/api/MyJobApp.js";
import Button from '@/View/components/globalButton.vue'
import TextAreaInput from '@/View/components/textAreaInput.vue'
import Dropdown from '@/View/components/dropdown.vue'
import DateTimePickerCustom from '@/View/components/dateTimepicker'
import TextField from '@/View/components/textfield.vue'
import AddCustomer from '@/View/customer/components/AddCustomer.vue'
import ItemSelection from '@/View/quotation/components/ItemSelection.vue'
import SelectedItmDetail from '@/View/quotation/components/selectedItems.vue'
import DepositPopup from '@/View/quotation/components/depositPopup.vue'
import deboucneMixin from '@/mixins/debounce.js'
import moment from 'moment'
export default {
  name: "customer",
  components: {
    Button,
    DepositPopup,
    SelectedItmDetail,
    TextAreaInput,
    ItemSelection,
    AddCustomer,
    TextField,
    DateTimePickerCustom,
    Dropdown,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      isDisable: false,
      depositDialog: false,
      depistTile: 'Deposit:',
      internlNotes: false,
      iscustomerNotes: false,
      cardHeight: 0,
      invoiceDatePickShow: false,
      invoiceDueDatePickShow: false,
      sendObjectForDeposit: {},
      quoteDetailObj: {},
      sendObject: {
        quotationMasterId: 0,
        customerId: 0,
        subTotal: 0,
        grandTotal: 0,
        depositAmt: 0,
        rawDepAmount: 0,
        quoteDate: '',
        expiryDate: '',
        customerNotes: '',
        internalNotes: '',
        isDepositPer: true,
        isActive: true,
        itemList: [],
      },
        customersList: [],
        searchForCustomer: '',
        searchApitext: '',
        addCustomer: false,
        itemSelect: false,
        itemSelectDetail: false,
        selectedItemForDetail: null,
      getCustList: () => {},
      errObj: {
        custErr: '',
        itemErr: '',
        dateError: '',
        depErr: '',
      }
    };
  },
  created() {
    window.addEventListener('resize', this.resizeWindowHandler())
  },
  watch: {
    sendObject: {
      handler () {
        console.log('this.sendObject--------------------------->', this.sendObject)
      },
      deep: true
    }
  },
  computed: {
  },
  mounted() {
    let currentDate1 = new Date()
    let y = currentDate1.setDate(currentDate1.getDate() + 30)
    this.sendObject.quoteDate = moment(new Date()).format('YYYY-MM-DD')
    this.sendObject.expiryDate = moment(new Date(y)).format('YYYY-MM-DD')
    console.log("route", this.$route.query);
     this.getCustList = this.debounce(function () {
      this.getCustomerList()
    }, 500)
      this.getCustomerList()
    this.$root.$on("addCustomerHandler", (response, data) => {
      console.log(response);
      if (response) {
        this.selectNewCustomer(data)
      }
      this.addCustomer = false
    });
    this.$root.$on("depositSelectionPopup", (response, data) => {
      if (response && data !== null) {
        this.sendObject.rawDepAmount = data.depRawAmount
        this.sendObject.isDepositPer = data.depIsPer
        if (this.sendObject.subTotal > 0) {
          this.getDepositCalculation()
        } else {
          this.depistTile = `Deposit:`
          this.sendObject.depositAmt = 0
          this.sendObject.rawDepAmount = 0
          this.sendObject.isDepositPer = true
        }
      }
      this.depositDialog = false
    });
    this.$root.$on("itemSelectedDetailQuote", (response, data) => {
      if (response && data !== null) {
        let fIndex = this.sendObject.itemList.findIndex(x => x.itemMasterId === data.itemMasterId)
        if (fIndex >= 0) {
          this.sendObject.itemList[fIndex].quoteItemTransactionId = data.quoteItemTransactionId
          this.sendObject.itemList[fIndex].itemName = data.itemName
          this.sendObject.itemList[fIndex].itemCode = data.itemCode
          this.sendObject.itemList[fIndex].itemQty = data.itemQty
          this.sendObject.itemList[fIndex].itemPrice = data.itemPrice
          this.sendObject.itemList[fIndex].itemAmount = data.itemAmount
          this.sendObject.itemList[fIndex].isTaxable = data.isTaxable
          this.sendObject.itemList[fIndex].itemUnit = data.itemUnit
          this.sendObject.itemList[fIndex].itemTransactionId = data.itemTransactionId
          this.sendObject.itemList[fIndex].itemDescription = data.itemDescription
          setTimeout(() => {
            this.calculateTaxes(this.sendObject.itemList)
          }, 100);
        }
      } else if (!response && data !== null) {
        let fIndex = this.sendObject.itemList.findIndex(x => x.itemMasterId === data.itemMasterId)
        if (fIndex >= 0) {
          this.sendObject.itemList.splice(fIndex, 1)
        }
        setTimeout(() => {
          this.calculateTaxes(this.sendObject.itemList)
        }, 100);
      }
      this.itemSelectDetail = false
    });
    this.$root.$on("itemSelectionHandler", (response, data) => {
      console.log(response, data);
      this.errObj.itemErr = ''
      if (response && data.length > 0) {
        this.sendObject.itemList = data
        setTimeout(() => {
          this.sendObject.depositAmt = 0
          this.calculateTaxes(data)
        }, 100);
      }
        this.itemSelect = false
        
    });
    if (parseInt(this.$route.params.quoteId) > 0) {
      this.getDetail()
    }
  },
  methods: {
    updateDate () {
      console.log('changes in date', this.sendObject.quoteDate)
      // let currentDate1 = new Date(this.sendObject.quoteDate)
      // let y = currentDate1.setDate(currentDate1.getDate() + 30)
      // this.sendObject.expiryDate = moment(new Date(y)).format('YYYY-MM-DD')
      if (new Date(this.sendObject.expiryDate) < new Date(this.sendObject.quoteDate)) {
        this.errObj.dateError = 'Expire Date should be greater than Quote Date'
      } else {
        this.errObj.dateError = ''
      }
    },
    createInvoice () {
      let isValid = true
      if (this.sendObject.customerId === 0) {
        isValid = false
        this.errObj.custErr = 'Please select Customer'
      }
      if (this.sendObject.itemList.length === 0) {
        isValid = false
        this.errObj.itemErr = 'Please add at least one item'
      }
      this.sendObject.grandTotal = this.sendObject.subTotal
      this.sendObject.depositAmt = parseFloat(this.sendObject.rawDepAmount)
      if (!this.sendObject.isDepositPer) {
        if (parseFloat(this.sendObject.rawDepAmount) > this.sendObject.grandTotal) {
          this.errObj.depErr = 'Deposit amount should be less than Total Amount'
          isValid = false
        }
      }
      if (this.errObj.dateError !== '') {
        isValid = false
      }
      if (isValid) {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
        MyJobApp.saveQuoteDetail(
          this.sendObject,
          response => {
            this.$router.push({name: 'quoteDetail', params: {quoteId: response.Data}})
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          (error) => {
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
      }
    },
    showitemDetail (data) {
      this.selectedItemForDetail = data
      this.itemSelectDetail = true
    },
    selectInvItems () {
      this.itemSelect = true
    },
    openDepositPopup (data) {
      this.sendObjectForDeposit = JSON.parse(JSON.stringify(data))
      this.depositDialog = true
    },
    addNewCustomer () {
      this.addCustomer = true
    },
    selectNewCustomer (data) {
      if (data !== null) {
        this.errObj.custErr = ''
        this.searchForCustomer = data.fullName
        // this.searchForCustomer = data.companyName
        this.sendObject.customerId = data.customerId
      } else {
        this.searchForCustomer = ''
        this.sendObject.customerId = 0
      }
    },
    discard () {
      // if (parseInt(this.$route.params.quoteId) > 0) {
      //   this.$router.push({name: 'quoteDetail', params: {quoteId: parseInt(this.$route.params.quoteId)}})
      // } else {
      //   this.$router.push({name: 'quotation'})
      // }
    },
    callsearchCust (text) {
      this.searchApitext = text
     this.getCustList()
    },
    calculateTaxes (itemList) {
        this.sendObject.subTotal = 0
        let list = itemList
        list.map((data) => {
          let itemAmount = data.itemAmount
          this.sendObject.subTotal += itemAmount
        })
        if (itemList.length > 0) {
          this.getDepositCalculation()
        } else {
          this.depistTile = `Deposit:`
          this.sendObject.depositAmt = 0
          this.sendObject.rawDepAmount = 0
          this.sendObject.isDepositPer = true
        }
    },
    getDepositCalculation () {
      if (this.sendObject.isDepositPer) {
        this.sendObject.depositAmt = (this.sendObject.subTotal * this.sendObject.rawDepAmount) / 100
        if (this.sendObject.rawDepAmount > 0) {
          this.depistTile = `Deposit (${this.sendObject.rawDepAmount}%):`
        } else {
          this.depistTile = `Deposit:`
        }
      } else {
        this.sendObject.depositAmt = this.sendObject.rawDepAmount
        if (this.sendObject.rawDepAmount > 0) {
          this.depistTile = `Deposit Amount:`
        } else {
          this.depistTile = `Deposit:`
        }
      }
      this.errObj.depErr = ''
    },
    getDetail () {
      this.$store.dispatch("SetLoader", { status: true, message: "Loading..." })
      MyJobApp.GetQuoteDetail(
        parseInt(this.$route.params.quoteId),
        (response) => {
          this.quoteDetailObj = response.Data
          console.log("quoteDetailObj", this.quoteDetailObj)
          this.sendObject.quoteDate = moment(this.quoteDetailObj.quoteDate).format('YYYY-MM-DD')
          this.sendObject.expiryDate = moment(this.quoteDetailObj.expiryDate).format('YYYY-MM-DD')
          this.sendObject.customerNotes = this.quoteDetailObj.customerNotes
          this.sendObject.internalNotes = this.quoteDetailObj.internalNotes
          this.sendObject.quotationMasterId =  this.quoteDetailObj.quotationMasterId
          this.sendObject.customerId = this.quoteDetailObj.customerId
          this.searchForCustomer = this.quoteDetailObj.customerFirstName + ' ' + this.quoteDetailObj.customerLastName
          this.sendObject.itemList = this.quoteDetailObj.itemList
          this.sendObject.grandTotal = this.quoteDetailObj.grandTotal
          this.sendObject.subTotal = this.quoteDetailObj.subTotal
          this.sendObject.rawDepAmount = this.quoteDetailObj.depositAmt
          this.sendObject.isDepositPer = this.quoteDetailObj.isDepositPer
          this.getDepositCalculation()
          if (this.quoteDetailObj.customerNotes !== '') {
            this.iscustomerNotes = true
          }
          if (this.quoteDetailObj.internalNotes !== '') {
            this.internlNotes = true
          }
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        () => {
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    getCustomerList () {
      MyJobApp.getGlobalCustLeadList(
        0,
        500,
        this.searchApitext,
        '',
        response => {
          let temp = response.Data.tableRow !== null ? response.Data.tableRow : []
          for (let index = 0; index < temp.length; index++) {
            let isCust = temp[index].isCustomer ? 'Customer' : 'Lead'
            temp[index].fullName = temp[index].companyName + ' (' + isCust + ')'
            // temp[index].fullName = temp[index].firstName + ' ' + temp[index].lastName + '  (' + isCust + ')'
          }
          if (this.$route.query.customerId > 0) {
            for (let index = 0; index < temp.length; index++) {
              if (temp[index].customerId === parseInt(this.$route.query.customerId)) {
                let isCust = temp[index].isCustomer ? 'Customer' : 'Lead'
                this.searchForCustomer = temp[index].companyName + ' (' + isCust + ')'
                this.sendObject.customerId = temp[index].customerId
                this.isDisable = true
              }
            }
          }
          this.customersList = temp
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    resizeWindowHandler() {
      this.cardHeight = window.innerHeight - 158
    },
  },
  beforeDestroy() {
    this.$root.$off("depositSelectionPopup");
    this.$root.$off("addCustomerHandler");
    this.$root.$off("itemSelectionHandler");
    this.$root.$off("itemSelectedDetailQuote");
  },
};
</script>
<style scoped>

</style>