<template>
  <div class="popup_overlay px-4">
  <div class="custom_dialog rounded-xl" id="itemdialogs" style="width: 700px; overflow: auto; max-height: 85%;">
    <div class="relative bg-white">
      <div>
        <div class="bg-primary flex justify-between p-3">
          <div class=" font-medium text-white heading-3">
              Item Edit
          </div>
        </div>
        <div class="p-4 text-text2">
          <div class=" font-bold mb-2 heading-3 text-text1">{{itemDetail.itemName}}</div>
          <div class=" heading-6 mb-4 ">Code:{{itemDetail.itemCode}}</div>
          <div class=" mb-4 sm:grid grid-cols-12">
              <div class=" mb-4 sm:mb-0 col-span-4">
                  <p class="heading-6 mb-2">Item Qty</p>
                  <div class="flex items-center gap-8">
                      <button
                          @click="minusQnty()"
                          :disabled="itemDetail.itemQty === 1"
                          :class="itemDetail.itemQty > 1  ? ' text-white' : ' text-white'"
                          class="outline outline-1 w-fit bg-primary outline-gray-200  outline-gray rounded py-2 px-2.5 shadow flex justify-center items-center"
                          :color="`primary`">
                          <span class="flex items-center"><i class="fa fa-minus heading-5"></i></span>
                      </button>
                      <div  class="text-text1 flex items-center">{{itemDetail.itemQty}}</div>
                      <button
                          @click="addQnty()"
                          class="bg-primary text-white outline outline-1 w-fit outline-gray-200  outline-gray rounded py-1 px-2.5 shadow"
                          :color="`primary`">
                          <span class=""><i class="fa fa-plus heading-5"></i></span>
                      </button>
                  </div>
              </div>
              <div class=" col-span-8">
                  <div class="mb-4">
                    <TextField
                      :inputId="`itmprice`"
                      :inputext="itemDetail.itemPrice"
                      :placholderText="`Item Price`"
                      :lableText="'Item Price'"
                      :inputType="'number'"
                      :textRight="true"
                      :autoFocus="false"
                      :setReadOnly="false"
                      :inputTypeText="'number'"
                      @inputChangeAction="setItemPrice" />

                      <!-- <NumberInput
                      :inputId="'itmprice'"
                      :inputext="itemDetail.itemPrice"
                      :placholderText="`Item Price`"
                      :lableText="'Item Price'"
                      :inputType="'text'"
                      :textRight="true"
                      :autoFocus="false"
                      :setReadOnly="false"
                      @inputChangeAction="setItemPrice"/> -->
                  </div>
                  <div>
                      <p class="text-text1 font-bold">Item Amount <span v-if="this.itemDetail.isTaxable">(A)</span></p>
                      <TextField
                      :inputId="`itmamt`"
                      :inputext="itemDetail.itemAmount"
                      :placholderText="`Item Amount`"
                      :lableText="''"
                      :inputType="'number'"
                      :textRight="true"
                      :autoFocus="false"
                      :setReadOnly="false"
                      :inputTypeText="'number'"
                      @inputChangeAction="setItemAmount" />
                      <!-- <NumberInput
                      :inputId="'itmamt'"
                      :inputext="itemDetail.itemAmount"
                      :placholderText="`Item Amount`"
                      :lableText="''"
                      :inputType="'text'"
                      :textRight="true"
                      :autoFocus="false"
                      :setReadOnly="false"
                      @inputChangeAction="setItemAmount"  /> -->
                  </div>
              </div>
            </div>
            <div class="my-4">
             <TextAreaInput
               :inputId="'itemdescrptionid'"
               :inputext="itemDetail.itemDescription"
               :placholderText="`Write description here...`"
               :lableText="'Item Description'"
               :inputType="'text'"
               :autoFocus="false"
               :cols="50"
               :rows="2"
               :setReadOnly="false"
               @inputChangeAction="(data) => itemDetail.itemDescription = data"  />
           </div>
           <div class="my-4 flex">
             <p class="text-error cursor-pointer" @click="removeConfirm = true">Remove this item</p>   
           </div>
        </div>
      </div>
        <div class=" sticky -bottom-1 bg-white w-full flex justify-end pr-3 pt-3">
          <div class="text-rigth flex gap-2 mb-3">
              <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="discard()"/>
              <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="saveItems()"/>
          </div>
          </div>
      </div>
  </div>
  <ConfirmBox v-if="removeConfirm" :message="'Delete this item from this quotation.'" :title="'Are you sure?'" />
</div>
</template>
<script>
import Button from '@/View/components/globalButton.vue'
import TextAreaInput from '@/View/components/textAreaInput.vue'
import TextField from '@/View/components/textfield.vue'
import deboucneMixin from '@/mixins/debounce.js'
import ConfirmBox from '@/View/components/ConfirmBox.vue'
export default {
  components: {
    Button,
    TextField,
    TextAreaInput,
    ConfirmBox,
  },
  mixins: [deboucneMixin],
  data () {
    return {
        taxlistErr: false,
        removeConfirm: false,
        addTaxinput: false,
        addNewItemTax: false,
        searchForItem: '',
        itemDetail: null,
        finalTaxList: [],
        itemtaxList: [],
        selectedTaxList: [],
        taxesAmmount: 0,
        getTaxesDataList: () => {}
    }
  },
  props: ["selectedItemForDetail"],
  created() {
    this.itemDetail = JSON.parse(JSON.stringify(this.selectedItemForDetail)) 
  },
  mounted () {
    this.$root.$on('confirmBoxHandler', (data) => {
      if (data) {
        this.$root.$emit('itemSelectedDetailQuote', false, this.itemDetail)
      }
      this.removeConfirm = false
      document.body.style = 'overflow: visible;'
    })
  },
  watch: {},
  methods: {
    setItemPrice (value) {
      this.itemDetail.itemPrice = value === '' ? 0 : Number(value) 
      this.itemDetail.itemAmount = this.itemDetail.itemPrice * this.itemDetail.itemQty
    },
    setItemAmount (value) {
        this.itemDetail.itemAmount = value === '' ? 0 : Number(value) 
        this.itemDetail.itemPrice = this.itemDetail.itemAmount / this.itemDetail.itemQty
    },
    discard () {
      this.$root.$emit('itemSelectedDetailQuote', false, null)
    },
    saveItems () {
      this.$root.$emit('itemSelectedDetailQuote', true, this.itemDetail)
    },
    minusQnty () {
        this.itemDetail.itemQty = this.itemDetail.itemQty - 1
        this.itemDetail.itemAmount = this.itemDetail.itemPrice * this.itemDetail.itemQty
    },
    addQnty () {
        this.itemDetail.itemQty += 1
        this.itemDetail.itemAmount = this.itemDetail.itemPrice * this.itemDetail.itemQty
    },
  },
  beforeDestroy () {
    this.$root.$off('confirmBoxHandler')
  }
}
</script>
<style scoped>

</style>