<template>
    <div class="popup_overlay px-4">
    <div class="custom_dialog rounded-xl" id="itemdialogs" style="width: 800px;max-height: 85%;">
      <div class="relative bg-screen_bg">
        <div class="bg-primary flex justify-between p-3 sticky top-0">
          <div class=" font-medium text-white heading-3">
              Items
          </div>
        </div>
        <div style="max-height: 60vh" class="overflow-auto">
          <div class="px-4 pt-4">
            <div class="w-72">
              <SearchTextInput @searchCall="searchCall" :placeholderText="`Search...`"/>
            </div>
          </div>
          <div v-if="itemsDataList.length > 0" class="p-4">
            <div v-for="(item, i) in itemsDataList" :key="i + 'i'" class="card rounded-xl bg-white mb-2 p-4">
              <div class="text-text1 flex justify-between mb-1">
                <p class="">{{item.itemName}}</p>
                <p>{{item.itemPrice.toFixed(2) | amountOnlyNumber}}</p>
              </div>
              <div class="sm:flex justify-between">
                <div class=" mb-2 sm:mb-0">
                  <p class=" mb-1 text-gray4 heading-7">Code: {{item.itemCode}}, Unit: {{item.itemUnit}}</p>
                  <p class=" text-gray4 heading-7">/{{item.categoryName}}</p>
                </div>
                <div class="flex justify-end items-center gap-4">
                  <Button class="py-1.5" v-if="item.qnty > 0" :btnBorder="true" :btnSize="'small'" :textColor="'text1'" :btnColor="'white'" :btnText="'Delete'" @buttonAction="deleteItem(i, item)"/>
                  <button v-if="item.qnty > 0"
                    @click="minusQnty(i, item)"
                    :disabled="item.qnty === 1"
                    :class="item.qnty > 1  ? ' text-primary' : ' text-white'"
                    class="outline outline-1 w-fit bg-info outline-gray-200  outline-gray rounded py-2 px-2.5 shadow flex justify-center items-center"
                    :color="`primary`">
                    <span class="flex items-center"><i class="fa fa-minus heading-5"></i></span>
                  </button>
                  <div v-if="item.qnty > 0" class="text-text1 flex items-center">{{item.qnty}}</div>
                  <button
                    @click="addQnty(i, item)"
                    class="bg-info text-primary outline outline-1 w-fit outline-gray-200  outline-gray rounded py-1 px-2.5 shadow"
                    :color="`primary`">
                    <span class=""><i class="fa fa-plus heading-5"></i></span>
                  </button>
                </div>
              </div>
              
            </div>
          </div>
          <div v-else class="px-4">
            <NoRecords :alertMessage="`No records found.`" />
          </div>
        </div>
          <div class=" sticky bottom-1 bg-screen_bg w-full flex justify-end pr-3 pt-3">
            <div class="text-rigth flex gap-2 mb-3">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="discard()"/>
                <Button :disabled="selectedItemList.length === 0" :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="saveItems()"/>
            </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import Button from '@/View/components/globalButton.vue'
import SearchTextInput from '@/View/components/searchTextInput.vue'
import NoRecords from '@/View/components/noRecords.vue'
// import TextField from '@/View/components/textfield.vue'
// import TextAreaInput from '@/View/components/textAreaInput.vue'
import deboucneMixin from '@/mixins/debounce.js'
import MyJobApp from '@/api/MyJobApp.js'
export default {
  name: 'sendEmail',
  components: {
    NoRecords,
    Button,
    SearchTextInput,
  },
  mixins: [deboucneMixin],
  data () {
    return {
      selectedItemList: [],
      itemsDataList: [],
      searchItemText: '',
      dialogHeight: '',
      getItems: () => {}
    }
  },
  props: ["itemsSelected"],
   created() {
    this.selectedItemList = JSON.parse(JSON.stringify(this.itemsSelected)) 
  },
  mounted () {
    this.itemListGet()
    this.getItems = this.debounce(function () {
      this.itemListGet()
    }, 500)
    this.dialogHeight = document.getElementById('itemdialogs').offsetHeight;
  },
  watch: {},
  methods: {
    deleteItem (index, data) {
      this.itemsDataList[index].qnty = 0

        let fIndex = this.selectedItemList.findIndex(x => x.itemMasterId === data.itemMasterId)
        if (fIndex >= 0) {
           this.selectedItemList.splice(fIndex, 1)
        }
    },
    minusQnty (index, data) {
      this.itemsDataList[index].qnty = data.qnty - 1

        let fIndex = this.selectedItemList.findIndex(x => x.itemMasterId === data.itemMasterId)
        if (fIndex >= 0) {
            this.selectedItemList[fIndex].itemAmount = data.itemPrice * this.itemsDataList[index].qnty
            this.selectedItemList[fIndex].itemQty = this.itemsDataList[index].qnty
        }
    },
    addQnty (index, data) {
      this.itemsDataList[index].qnty += 1
      let taxList = JSON.parse(data.itemTaxes)

        let fIndex = this.selectedItemList.findIndex(x => x.itemMasterId === data.itemMasterId)
        if (fIndex >= 0) {
            this.selectedItemList[fIndex].itemAmount = data.itemPrice * this.itemsDataList[index].qnty
            this.selectedItemList[fIndex].itemQty = this.itemsDataList[index].qnty
        } else {
          let obj = {
            quoteItemTransactionId: 0,
            itemMasterId: data.itemMasterId,
            itemCode: data.itemCode,
            itemName: data.itemName,
            itemDescription: data.itemDescription,
            itemPrice: data.itemPrice,
            itemQty: this.itemsDataList[index].qnty,
            itemUnit: data.itemUnit,
            itemAmount: data.itemPrice * this.itemsDataList[index].qnty,
            isTaxable: taxList.itemTaxList !== null ? true : false,
            taxRateList: taxList.itemTaxList !== null ? taxList.itemTaxList : []
          }
          this.selectedItemList.push(obj)
        }
    },
     searchCall (data) {
      this.searchItemText = data
      this.getItems()
    },
    discard () {
      this.$root.$emit('itemSelectionHandler', false, null)
    },
    saveItems () {
      this.$root.$emit('itemSelectionHandler', true, this.selectedItemList)
    },
    itemListGet () {
      MyJobApp.getItemsList(
        0,
        20,
        this.searchItemText,
        'addedDate',
        'DESC',
        true,
        0,
        true,
        response => {
          let tempList = []
          if (response.Data.tableRow !== null) {
              response.Data.tableRow.map((data)=> {
                  tempList.push({...data, qnty: 0})
              }) 
          }
          setTimeout(() => {
            if (this.selectedItemList.length > 0 && tempList.length > 0) {
            this.selectedItemList.map(element => {
              let fIndex = tempList.findIndex(x => x.itemMasterId === element.itemMasterId)
                if (fIndex >= 0) {
                    tempList[fIndex].qnty += element.itemQty
                }
            });
          }
          }, 50);
          this.itemsDataList = tempList

          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    }
  },
  beforeDestroy () {
    // this.$root.$off('closeProfileDialog')
  }
}
</script>
<style scoped>

</style>