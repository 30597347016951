<template>
  <div class="popup_overlay px-4">
      <div class="custom_dialog rounded-xl" style="width: 400px; overflow: auto; max-height: 85%;">
        <div class="relative bg-white">
          <div>
            <div class="bg-primary flex justify-between p-3">
              <div class=" font-medium text-white heading-3">
                  Deposit
              </div>
            </div>
            <div class="p-4 text-text2">
              <div class=" heading-5 mb-4 flex items-center">Deposit Type:
                <div class="flex items-center pl-4">
                  <button
                    @click="depositBtn(true)" :class="depIsPer ? 'bg-primary text-white' : 'bg-white text-primary border border-gray3'"
                    class="rounded py-2 px-2.5 shadow flex justify-center items-center"
                    >
                    <span class="flex items-center"><i class="fas fa-percent heading-5"></i></span>
                  </button>
                  <button
                    @click="depositBtn(false)" :class="!depIsPer ? 'bg-primary text-white' : 'bg-white text-primary border border-gray3'"
                    class="rounded py-2 px-2.5 shadow flex justify-center items-center ml-5"
                    >
                    <span class="flex items-center"><i class="fas fa-dollar heading-5"></i></span>
                  </button>
                </div>
              </div>
              <div>
                <input  @keypress="isNumber($event)" :class="depErr ? 'border border-error' : 'border border-gray2  focus:border-primary focus:ring-1 focus:ring-primary'"
                placeholder="Enter Deposit"
                v-model="depRawAmount"
                class="h-11 cust_text_field px-2 w-40 bg-white rounded items-center  flex text-text1">
              </div>
              <p v-if="depErr !== ''" class="text-error heading-7">{{depErr}}</p>
            </div>
            <div class="flex justify-end p-3">
              <div class="text-rigth flex gap-2">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="closePopup(false)"/>
                <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="closePopup(true)"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import Button from '@/View/components/globalButton.vue'
export default {
  props: ['Depobject'],
  components: {
    Button,
  },
  data() {
    return {
      depErr: '',
      depIsPer: true,
      totalAmount: 0,
      depRawAmount: 0
    };
  },
  mounted() {
    this.depRawAmount = this.Depobject.rawDepAmount
    this.depIsPer = this.Depobject.isDepositPer
    this.totalAmount = this.Depobject.subTotal
  },
  watch: {
    depRawAmount: {
      handler() {
        if (this.depRawAmount === '') {
          this.depRawAmount = 0
        }
        if (this.depRawAmount !== 0) {
          this.depRawAmount = this.depRawAmount.toString().replace(/^0+/, '')
        }
        if (this.depIsPer && this.depRawAmount > 100) {
          this.depErr = 'Deposit Value Cannot be More than 100%'
          var lastDigit = this.depRawAmount.toString().slice(1);
          this.depRawAmount = lastDigit
        } else {
          this.depErr = ''
        }
        if (!this.depIsPer && this.depRawAmount > this.totalAmount) {
          this.depErr = 'Deposit Value Cannot be More than Items Total'
        } else {
          this.depErr = ''
        }
      },
      deep: true
    }
  },
  beforeDestroy() {
  },
  methods: {
    depositBtn (value) {
      this.depIsPer = value
      this.depRawAmount = 0
      this.depErr = ''
    },
    closePopup (value) {
      if (value) {
        if (this.depErr === '') {
          let Obj = {
            depIsPer: this.depIsPer,
            depRawAmount: this.depRawAmount
          }
          this.$root.$emit('depositSelectionPopup', value, Obj)
        }
      } else {
        this.$root.$emit('depositSelectionPopup', value, null)
      }
    },
    isNumber (KeyboardEvent) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '+', '.']
      const keyPressed = KeyboardEvent.key
      if (!keysAllowed.includes(keyPressed)) {
        KeyboardEvent.preventDefault()
      }
    },
  }
};
</script>
<style scoped>
.quote_name_col {
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>